  <template>
  <v-card class="elevation-0 overflow-hidden">
    <div>
      <SnackBar :SnackBarComponent="SnackBarComponent" />
      <Overlay :overlay="overlay" />
      <v-row class="mt-6 ml-5 font-weight-medium">
        <span>Presence Report by Day, Month or Year</span>
      </v-row>
      <v-row class="mt-5 ml-5">
        <span
          >Based on your selection an Excel/Pdf Report is generated and it is
          available for Download
        </span>
      </v-row>

      <v-row no-gutters class="mb-n16">
        <v-col class="mt-6" cols="12" md="3" sm="3" lg="3" xl="3">
          <v-toolbar dense elevation="0" class="ml-3">
            <v-select
              label="Duration"
              dense
              outlined
              item-text="text"
              item-value="value"
              :items="reportItems"
              style="max-width: 170px"
              v-model="reportStatus"
              class="mt-11 mr-2"
            ></v-select>
          </v-toolbar>
        </v-col>
      </v-row>

      <div v-if="reportStatus == 'yearly'" class="mt-8 pt-10">
        <v-row>
          <v-radio-group class="ml-9" v-model="radio">
            <v-radio label="All" value="orange"></v-radio>
            <v-row>
              <v-col style="margin-left: 100px" class="mt-n8">
                <v-radio label="Members" value="red"></v-radio>
              </v-col>
            </v-row>
          </v-radio-group>

          <v-select
            v-model="YearlyMemberstatus"
            :items="Yearlymembersitemstatus"
            label="Member Status"
            style="max-width: 200px"
            item-text="text"
            item-value="value"
            outlined
            dense
            square
            class="ml-3 mt-2"
          ></v-select>

          <!-- <v-autocomplete
            label="Filter by Home Location"
            dense
            outlined
            item-text="location_name"
            item-value="location_id"
            style="max-width: 200px"
            :items="locationItems"
            v-model="LocationData"
            class="ml-2 mr-2 mt-2"
          ></v-autocomplete>
          <v-autocomplete
            label="Filter by Scan Location"
            dense
            outlined
            item-text="location_name"
            item-value="location_id"
            style="max-width: 200px"
            :items="locationscanItems"
            v-model="Locationscan"
            class="ml-2 mr-2 mt-2"
          ></v-autocomplete> -->
        </v-row>
        <v-row class="ml-7 mt-3">
          <v-select
            v-if="YearlyMemberstatus == 'ACTIVE'"
            v-model="select_members"
            :disabled="radio == 'orange'"
            :items="selectmembers"
            label="Select Member"
            style="max-width: 200px"
            item-text="text"
            item-value="value"
            outlined
            dense
            square
            class="mr-2"
          ></v-select>
          <v-select
            v-else
            v-model="select_members_inactive"
            :disabled="radio == 'orange'"
            :items="selectmembers_inactive"
            label="Select Member"
            style="max-width: 200px"
            item-text="text"
            item-value="value"
            outlined
            dense
            square
            class="mr-2"
          ></v-select>
          <v-select
            label="Filter by Year"
            v-model="selectedYear"
            :items="filteredYears"
            item-text="text2"
            item-value="value2"
            style="max-width: 200px"
            outlined
            dense
            square
            class=""
          ></v-select>
          <div>
            <v-btn
              @click="yearly_report()"
              :loading="isLoading"
              small
              class="mr-2 ml-2 mt-2"
              color="primary white--text"
              >Generate Report</v-btn
            >
          </div>
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#D97D54"
                class="gradient-bg white--text ml-2 mt-2"
                text
                small
                v-on="on"
                v-bind="attrs"
                depressed
                @click="get_list()"
              >
                <v-icon class="pr-2">mdi-refresh</v-icon> Refresh
              </v-btn>
            </template>
            <span class="white--text">Refresh</span>
          </v-tooltip>
        </v-row>
      </div>
      <div v-if="reportStatus == 'monthly'" class="mt-8 pt-10">
        <v-row class="ml-1 mt-2">
          <v-select
            v-model="enumreport"
            :items="enummonthlyreport"
            label="Reports"
            style="max-width: 200px"
            item-text="text"
            item-value="value"
            outlined
            dense
            square
            class="ml-6 mt-4"
          />
          <v-select
            label="Filter by Year"
            v-model="selectedYear"
            :items="filteredYears"
            item-text="text2"
            item-value="value2"
            style="max-width: 200px"
            outlined
            dense
            square
            class="ml-3 mt-4"
            @change="month_get"
          />
          <v-select
            label="Filter by Month"
            v-model="selectedMonth"
            :items="formattedMonthItems"
            item-text="text"
            item-value="value"
            style="max-width: 200px"
            outlined
            dense
            square
            class="ml-3 mt-4"
          />
          <v-autocomplete
            label="Filter by Home Location"
            dense
            outlined
            item-text="location_name"
            v-show="enumreport != 'DATA_CORRECTION'"
            item-value="location_id"
            style="max-width: 200px"
            :items="locationItems"
            v-model="LocationData"
            class="ml-2 mr-2 mt-4"
          ></v-autocomplete>
        </v-row>

        <v-row class="ml-1 mt-2">
          <v-autocomplete
            label="Filter by Scan Location"
            dense
            outlined
            item-text="location_name"
            item-value="location_id"
            style="max-width: 200px"
            :items="locationscanItems"
            v-model="Locationscan"
            class="ml-7 mr-2 mt-4"
          ></v-autocomplete>
          <v-select
            v-model="monthlymembersstatus"
            :items="monthlystatus"
            label="Member Status"
            style="max-width: 200px"
            item-text="text"
            item-value="value"
            v-show="enumreport != 'DATA_CORRECTION'"
            outlined
            dense
            square
            class="mt-4"
          />
          <v-select
            v-if="
              monthlymembersstatus != 'DEACTIVE' &&
              enumreport != 'PROOF_OF_WORK'
            "
            v-model="select_members_monthly"
            :items="selectmembers_Monthly"
            label="Select Members"
            style="max-width: 200px"
            item-text="text"
            item-value="value"
            v-show="enumreport != 'DATA_CORRECTION'"
            outlined
            dense
            square
            class="ml-2 mt-4"
          />
          <v-select
            v-if="
              monthlymembersstatus == 'DEACTIVE' &&
              enumreport != 'PROOF_OF_WORK'
            "
            v-model="select_members_monthly_inactive"
            :items="selectmembers_Monthly_inactive"
            label="Select Members"
            style="max-width: 200px"
            item-text="text"
            item-value="value"
            outlined
            dense
            square
            class="ml-2 mt-4"
          />
          <v-select
            v-if="
              monthlymembersstatus == 'DEACTIVE' &&
              enumreport == 'PROOF_OF_WORK'
            "
            v-model="select_members_monthly_inactive_with_proof"
            :items="selectmembers_Monthly_inactive_proof_of_work"
            label="Select Members"
            style="max-width: 200px"
            item-text="text"
            item-value="value"
            outlined
            dense
            square
            class="ml-2 mt-4"
          />
          <v-select
            v-if="
              monthlymembersstatus != 'DEACTIVE' &&
              enumreport == 'PROOF_OF_WORK'
            "
            v-model="select_members_monthly_active_with_proof"
            :items="selectmembers_Monthly_active_proof_of_work"
            label="Select Members"
            style="max-width: 200px"
            item-text="text"
            item-value="value"
            outlined
            dense
            square
            class="ml-2 mt-4"
          />
          <v-select
            label="Export Type"
            v-if="enumreport == 'PROOF_OF_WORK'"
            v-model="proofReport"
            :items="proofReportitems"
            item-text="text"
            item-value="value"
            style="max-width: 200px"
            class="ml-2 mt-4"
            outlined
            dense
          />
          <v-btn
            @click="send_monthly_report()"
            :loading="isLoading"
            small
            class="ml-4 mt-6"
            color="primary white--text"
            >Generate Report</v-btn
          >
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#D97D54"
                class="gradient-bg white--text ml-4 mt-6"
                text
                small
                v-on="on"
                v-bind="attrs"
                depressed
                @click="get_list()"
              >
                <v-icon class="pr-2">mdi-refresh</v-icon> Refresh
              </v-btn>
            </template>
            <span class="white--text">Refresh</span>
          </v-tooltip>
        </v-row>
      </div>
      <div v-if="reportStatus == 'Weekly'" class="mt-8 pt-10">
        <v-row class="ml-1 mt-2">
          <v-select
            v-model="weektypereport"
            :items="weektypereportitems"
            label="Reports"
            style="max-width: 200px"
            item-text="text"
            item-value="value"
            outlined
            dense
            square
            class="ml-6 mt-4"
          />
          <v-dialog
            ref="dialog"
            v-model="weakmodel"
            color="#f4813f"
            :return-value.sync="weakdate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormattedweek"
                dense
                outlined
                class="mt-4 ml-6"
                persistent-hint
                readonly
                label="Filter by Date"
                color="#f4813f"
                append-icon="mdi-calendar"
                style="max-width: 150px"
                @click:append="weakdate ? getweekcal() : getweekcal()"
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              :max="
                new Date(new Date().setDate(new Date().getDate() - 2))
                  .toISOString()
                  .substr(0, 10)
              "
              :min="minmonth"
              v-model="weakdate"
              color="#f4813f"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="#f4813f" @click="weakmodel = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="#f4813f"
                v-on:click="get_weaklist2()"
                @click="$refs.dialog.save(weakdate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
          <!-- <v-autocomplete
            label="Filter by Home Location"
            dense
            outlined
            item-text="location_name"
            item-value="location_id"
            style="max-width: 200px"
            :items="locationItems"
            v-model="LocationData"
            class="ml-2 mr-2 mt-4"
          ></v-autocomplete>
          <v-autocomplete
            label="Filter by Scan Location"
            dense
            outlined
            item-text="location_name"
            item-value="location_id"
            style="max-width: 200px"
            :items="locationscanItems"
            v-model="Locationscan"
            class="ml-2 mr-2 mt-4"
          ></v-autocomplete> -->
        </v-row>
        <v-row class="ml-1 mt-2">
          <v-select
            v-model="weeklymembersstatus"
            :items="weeklystatusitems"
            label="Member Status"
            style="max-width: 200px"
            item-text="text"
            item-value="value"
            outlined
            dense
            square
            class="ml-6 mt-4"
          />
          <v-select
            v-show="weeklymembersstatus != 'DEACTIVE'"
            v-model="select_members_weekly"
            :items="selectmembers_weekly_active"
            label="Select Members"
            style="max-width: 200px"
            item-text="text"
            item-value="value"
            outlined
            dense
            square
            class="ml-2 mt-4"
          />
          <v-select
            v-show="weeklymembersstatus == 'DEACTIVE'"
            v-model="select_members_weekly_inactive"
            :items="selectmembers_weekly_inactive"
            label="Select Members"
            style="max-width: 200px"
            item-text="text"
            item-value="value"
            outlined
            dense
            square
            class="ml-2 mt-4"
          />
          <v-btn
            @click="send_weekly_report()"
            :loading="isLoading"
            small
            class="ml-4 mt-6"
            color="primary white--text"
            >Generate Report</v-btn
          >
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#D97D54"
                class="gradient-bg white--text ml-4 mt-6"
                text
                small
                v-on="on"
                v-bind="attrs"
                depressed
                @click="get_list()"
              >
                <v-icon class="pr-2">mdi-refresh</v-icon> Refresh
              </v-btn>
            </template>
            <span class="white--text">Refresh</span>
          </v-tooltip>
        </v-row>
      </div>
      <div v-if="reportStatus == 'daily'" class="mt-10 ml-13 pt-10">
        <!-- <v-row class="ml-n8 mt-2">
          <v-autocomplete
            label="Filter by Home Location"
            dense
            outlined
            item-text="location_name"
            item-value="location_id"
            style="max-width: 200px"
            :items="locationItems"
            v-model="LocationData"
            class="ml-2 mr-2 mt-2"
          ></v-autocomplete>
          <v-autocomplete
            label="Filter by Scan Location"
            dense
            outlined
            item-text="location_name"
            item-value="location_id"
            style="max-width: 200px"
            :items="locationscanItems"
            v-model="Locationscan"
            class="ml-2 mr-2 mt-2"
          ></v-autocomplete>
        </v-row> -->
        <v-row class="mt-4 ml-n6">
          <v-dialog
            ref="dialog"
            v-model="modal"
            color="#f4813f"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormatted"
                dense
                outlined
                class="mr-2"
                persistent-hint
                :disabled="isLoading == true"
                label="Filter by Date"
                color="#f4813f"
                append-icon="mdi-calendar"
                @click:append="date ? getcal() : getcal()"
                v-bind="attrs"
                readonly
                v-on="on"
                style="max-width: 200px"
              >
              </v-text-field>
            </template>
            <v-date-picker
              :min="minmonth"
              :max="new Date().toISOString().substr(0, 10)"
              v-model="date"
              color="#f4813f"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="#f4813f" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="#f4813f"
                v-on:click="get_daily_present_reports(shiftName)"
                @click="$refs.dialog.save(date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
          <v-select
            v-model="dailystatus"
            :items="dailyreport"
            label="Daily Status"
            style="max-width: 200px"
            item-text="text"
            item-value="value"
            outlined
            dense
            square
            class="mr-2"
          ></v-select>
          <v-select
            label="Export Type"
            v-if="dailystatus == 'PROOF_OF_WORK'"
            v-model="proofReport"
            :items="proofReportitems"
            item-text="text"
            item-value="value"
            style="max-width: 200px"
            class="ml-2 mr-2"
            outlined
            dense
          ></v-select>
          <v-btn
            @click="send_daily_report()"
            :loading="isLoading"
            small
            class="mt-2"
            color="primary white--text"
            >Generate Report</v-btn
          >
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#D97D54"
                class="gradient-bg white--text ml-4 mt-2"
                text
                small
                v-on="on"
                v-bind="attrs"
                depressed
                @click="get_list()"
              >
                <v-icon class="pr-2">mdi-refresh</v-icon> Refresh
              </v-btn>
            </template>
            <span class="white--text">Refresh</span>
          </v-tooltip>
        </v-row>
      </div>
      <v-row class="ma-3 mt-5" no-gutters>
        <v-col cols="12" md="12" lg="12" xl="12" xs="12" sm="12">
          <v-data-table
            :height="height"
            class="overflow-y-auto overflow"
            dense
            :headers="tableexportdata"
            :items="exporttable"
            :options.sync="pagination"
            :items-per-page="5"
            :footer-props="{
              'items-per-page-options': [5, 10, 15],
            }"
            :fixed-header="fixed"
            loading-text="Loading... Please wait"
            :loading="isLoadingexport"
          >
            <template v-slot:no-data>
              <v-alert :value="true" class="gradient-bg2 white--text">
                No Data available.
              </v-alert>
            </template>
            <template v-slot:[`item.report_generated_for`]="{ item }">
              <div
                class="overflow ml-5"
                v-if="item.report_generated_for == undefined"
              >
                -
              </div>
              <div v-else>
                {{ item.report_generated_for }}
              </div>
            </template>

            <template v-slot:[`item.report_type`]="{ item }">
              <div class="overflow" v-if="item.report_type == 'DAILY_PRESENT'">
                <span>Daily Present</span>
              </div>
              <div class="overflow" v-if="item.report_type == 'YEARLY'">
                <span>Yearly </span>
              </div>
              <div
                class="overflow"
                v-if="item.report_type == 'DAILY_PROOF_OF_WORK'"
              >
                <span v-if="item.report_s3_key != undefined">
                  Daily Proof Of Work ({{
                    item.report_s3_key.split(".").pop().split("?")[0] == "pdf"
                      ? "PDF"
                      : "Excel"
                  }}
                  )</span
                >
                <span v-else> Daily Proof Of Work</span>
              </div>
              <div
                class="overflow"
                v-if="item.report_type == 'MONTHLY_PROOF_OF_WORK'"
              >
                <span v-if="item.report_s3_key != undefined">
                  Monthly Proof Of Work ({{
                    item.report_s3_key.split(".").pop().split("?")[0] == "pdf"
                      ? "PDF"
                      : "Excel"
                  }}
                  )
                </span>
                <span v-else> Monthly Proof Of Work </span>
              </div>

              <div
                class="overflow"
                v-if="item.report_type == 'MONTHLY_SCANS_COUNT'"
              >
                <span>Monthly Scan Count </span>
              </div>

              <div class="overflow" v-if="item.report_type == 'MONTHLY_SWIPES'">
                <span>Monthly Swipes</span>
              </div>
              <div
                class="overflow"
                v-if="item.report_type == 'MONTHLY_DURATION'"
              >
                <span>Monthly Duration </span>
              </div>
              <div
                class="overflow"
                v-if="item.report_type == 'MONTHLY_PRESENT_ABSENT_REPORT'"
              >
                <span>Monthly Present And Absent </span>
              </div>
              <div
                class="overflow"
                v-if="item.report_type == 'MONTHLY_NORMALIZE'"
              >
                <span>Monthly Normalize </span>
              </div>
              <div
                class="overflow"
                v-if="item.report_type == 'WEEKLY_SCANS_COUNT'"
              >
                <span>Weekly Scan Count </span>
              </div>

              <div class="overflow" v-if="item.report_type == 'WEEKLY_SWIPES'">
                <span>Weekly Swipes</span>
              </div>
              <div
                class="overflow"
                v-if="item.report_type == 'WEEKLY_DURATION'"
              >
                <span>Weekly Duration </span>
              </div>
              <div
                class="overflow"
                v-if="item.report_type == 'WEEKLY_PRESENT_ABSENT_REPORT'"
              >
                <span>Weekly Present And Absent </span>
              </div>
              <div
                class="overflow"
                v-if="item.report_type == 'WEEKLY_NORMALIZE'"
              >
                <span>Weekly Normalize </span>
              </div>
              <div
                class="overflow"
                v-if="item.report_type == 'WEEKLY_LEAVE_REPORT'"
              >
                <span>Weekly Leave </span>
              </div>

              <div class="overflow" v-if="item.report_type == 'DAILY_ABSENT'">
                <span>Daily Absent</span>
              </div>
              <!-- <div class="overflow" v-if="item.report_type == 'MONTHLY_SCANS_COUNT'">
                <span>Monthly Scans Leave</span>
              </div> -->
              <div
                class="overflow"
                v-if="item.report_type == 'MONTHLY_LEAVE_REPORT'"
              >
                <span>Monthly Leave</span>
              </div>
              <div class="overflow" v-if="item.report_type == 'LEAVE_REPORT'">
                <span>Daily Leave</span>
              </div>
              <div
                class="overflow"
                v-if="item.report_type == 'MONTHLY_EARLY_OUT'"
              >
                <span>Monthly Early Out</span>
              </div>
              <div
                class="overflow"
                v-if="item.report_type == 'MONTHLY_LATE_ARRIVAL'"
              >
                <span>Monthly Late Arrival</span>
              </div>
              <div
                class="overflow"
                v-if="item.report_type == 'MONTHLY_SHORT_DURATION'"
              >
                <span>Monthly Short Duration</span>
              </div>
              <div
                class="overflow"
                v-if="item.report_type == 'MONTHLY_DATA_CORRECTION'"
              >
                <span>Monthly Data Correction</span>
              </div>
            </template>
            <template v-slot:[`item.report_user_type`]="{ item }">
              <div class="overflow" v-if="item.report_user_type == 'ALL_USER'">
                <span>All</span>
              </div>
              <div
                class="overflow"
                v-else-if="item.report_user_type == 'ALL_USERS'"
              >
                <span>All</span>
              </div>
              <div class="overflow" v-else-if="item.report_user_type == 'ALL'">
                <span>All</span>
              </div>
              <div
                class="overflow"
                v-else-if="item.report_user_type == 'MONTHLY__ALL_USERS'"
              >
                <span>All</span>
              </div>
              <div
                class="overflow"
                v-else-if="item.report_user_type == 'MONTHLY_ALL_USERS'"
              >
                <span>All</span>
              </div>
              <div class="overflow" v-else>
                <span>{{ item.report_user_type }}</span>
              </div>
            </template>
            <template v-slot:[`item.report_generated_on`]="{ item }">
              <span
                class="overflow"
                v-text="get_date(item.report_generated_on)"
              ></span>
            </template>
            <template v-slot:[`item.reported_by`]="{ item }">
              <div class="overflow" v-if="item.reported_by != undefined">
                {{ item.reported_by }}
              </div>
              <div v-else>{{ $store.getters.get_useremail }}</div>
            </template>
            <template v-slot:[`item.report_s3_key`]="{ item }">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <a
                    v-on="on"
                    v-show="item.report_s3_key != undefined"
                    @click="download_invoice(item)"
                  >
                    <v-icon v-on="on" small class="primary--text mr-1"
                      >mdi-download
                    </v-icon>
                  </a>
                  <span v-show="item.report_s3_key == undefined" class="ml-2"
                    >-</span
                  >
                </template>

                <span
                  v-if="
                    item.report_s3_key != null ||
                    item.report_s3_key != undefined
                  "
                  class="white--text"
                  >Download</span
                >
              </v-tooltip>
            </template>
            <template v-slot:[`item.report_generation_status`]="{ item }">
              <span
                v-if="item.report_generation_status == 'COMPLETED'"
                class="green--text"
                >{{ item.report_generation_status }}</span
              >
              <v-tooltip bottom color="primary" v-else>
                <template v-slot:activator="{ on }">
                  <span class="orange--text" v-on="on">{{
                    item.report_generation_status
                  }}</span>
                </template>
                <span class="white--text">Refresh </span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import moment from "moment";
import { API, graphqlOperation } from "aws-amplify";
import { YearlyReport } from "@/graphql/queries.js";
import { daily_report } from "@/graphql/mutations.js";
import { monthReport, all_weekly_kind_report } from "@/graphql/mutations.js";
import { get_list_of_reports } from "@/graphql/queries.js";
import SnackBar from "@/components/SnackBar.vue";
import Overlay from "@/components/Overlay.vue";
import { ListAllUsers } from "@/graphql/queries.js";
import { GetLocation } from "@/mixins/GetLocationList.js";
const FileSaver = require("file-saver");
import axios from "axios";
export default {
  components: {
    SnackBar,
    Overlay,
  },
  mixins: [GetLocation],
  data: (vm) => ({
    weakdate: new Date(new Date().setDate(new Date().getDate() - 2))
      .toISOString()
      .substr(0, 10),
    weakmodel: false,
    SnackBarComponent: {},
    reportStatus: "yearly",
    LocationData: "ALL",
    locationItems: [],
    locationscanItems: [],
    Locationscan: "ALL",
    radio: "",
    reportItems: [
      { text: "Yearly", value: "yearly" },
      { text: "Monthly", value: "monthly" },
      { text: "Weekly", value: "Weekly" },
      { text: "Daily", value: "daily" },
    ],
    YearlyMemberstatus: "ACTIVE",
    Yearlymembersitemstatus: [
      { text: "ACTIVE", value: "ACTIVE" },
      { text: "INACTIVE", value: "DEACTIVE" },
    ],
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    select_members_weekly: "ALL",
    selectmembers_weekly_active: [],
    select_members: "",
    isLoadingexport: false,
    select_members_inactive: "",
    selectmembers_inactive: [],
    select_members_monthly: "ALL",
    select_members_monthly_inactive: "ALL",
    selectmembers_Monthly_inactive: [],
    select_members_weekly_inactive: "ALL",
    filtermonth: new Date().getMonth() + 1,
    search_admins: [],
    search_admins1: [],
    search_admin: [],
    date: new Date().toISOString().substr(0, 10),
    isLoading: false,
    searchAdmin: [],
    fixed: true,
    height: 0,
    exporttable: [],
    searchAdmins: [],
    searchAdmins1: [],

    overlay: false,
    modal: false,
    selectmembers: [],
    selectmembers_Monthly: [],
    pagination: {
      itemsPerPage: 5,
      page: 1,
    },
    yearmonth: [],
    proofReport: "1",
    proofReportitems: [
      { text: "PDF", value: "1" },
      { text: "Excel", value: "2" },
    ],
    select_members_monthly_inactive_with_proof: "",
    select_members_monthly_active_with_proof: "",
    selectmembers_Monthly_inactive_proof_of_work: [],
    yearList: [],
    selectmembers_Monthly_active_proof_of_work: [],
    weeklymembersstatus: "ACTIVE",
    weeklystatusitems: [
      { text: "ACTIVE", value: "ACTIVE" },
      { text: "DEACTIVE", value: "DEACTIVE" },
    ],
    selectedYear: new Date().getFullYear(),
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    dailystatus: "P",
    dailyreport: [
      { text: "Present", value: "P" },
      { text: "Absent", value: "A" },
      { text: "Leave", value: "LEAVE_REPORT" },
      // { text: "Proof of Work", value: "PROOF_OF_WORK" },
    ],
    monthlymembersstatus: "ACTIVE",
    monthlystatus: [
      // { text: "ALL", value: "ALL" },
      { text: "ACTIVE", value: "ACTIVE" },
      { text: "DEACTIVE", value: "DEACTIVE" },
    ],
    enumreport: "SCANS_COUNT",
    enummonthlyreport: [
      { text: "Scans Count", value: "SCANS_COUNT" },
      { text: "Normalize", value: "NORMALIZE" },
      { text: "Swipes", value: "SWIPES" },
      { text: "Duration ", value: "DURATION" },
      { text: " Present And Absent", value: "PRESENT_ABSENT_REPORT" },
      { text: "Early Out", value: "EARLY_OUT" },
      { text: "Late In / Arrival", value: "LATE_ARRIVAL" },
      { text: "Leave", value: "LEAVE_REPORT" },
      { text: "Short Duration", value: "SHORT_DURATION" },
      // { text: "Proof of Work", value: "PROOF_OF_WORK" },
      // { text: "Data Correction", value: "DATA_CORRECTION" },
    ],
    weektypereport: "SCANS_COUNT",
    weektypereportitems: [
      { text: "Scans Count", value: "SCANS_COUNT" },
      { text: "Normalize", value: "NORMALIZE" },
      { text: "Swipes", value: "SWIPES" },
      { text: "Duration ", value: "DURATION" },
      { text: " Present And Absent", value: "PRESENT_ABSENT_REPORT" },
      { text: "Leave", value: "LEAVE_REPORT" },
    ],
    selectmembers_weekly_inactive: [],
    selectedDate: "",
    selectedMonth: "",
    formattedMonthItems: [],
    nextToken: null,
    tableexportdata: [
      { text: "Report Type", value: "report_type" },
      { text: "User Type", value: "report_user_type" },
      { text: "Generated For", value: "report_generated_for" },
      { text: "Generated on", value: "report_generated_on" },
      { text: "Generated By", value: "reported_by" },
      { text: "Status", value: "report_generation_status" },
      { text: "Report", value: "report_s3_key" },
    ],
    minmonth: "",
  }),
  async mounted() {
    this.overlay = true;
    await this.userlist();
    this.overlay = false;
    // this.height = window.innerHeight - 220;
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    computedDateFormattedweek() {
      return this.formatDate(this.weakdate);
    },
    filteredYears() {
      const currentYear = new Date().getFullYear();
      var dateComponents = this.selectedDate.split(/[\/,\s:]+/);
      // Extracting components
      var month = parseInt(dateComponents[0], 10) - 1; // Month is zero-indexed in JavaScript Date
      var day = parseInt(dateComponents[1], 10);
      var year = parseInt(dateComponents[2], 10);
      var hour = parseInt(dateComponents[3], 10);
      var minute = parseInt(dateComponents[4], 10);
      var second = parseInt(dateComponents[5], 10);
      var period = dateComponents[6]; // "AM" or "PM"

      // Adjust hour based on AM/PM
      if (period === "PM" && hour !== 12) {
        hour += 12;
      } else if (period === "AM" && hour === 12) {
        hour = 0;
      }

      var date = new Date(year, month, day, hour, minute, second);

      const selectedDateYear = date.getFullYear();
      const years = [];

      for (let year = selectedDateYear; year <= currentYear; year++) {
        years.push(year);
      }
      return years;
    },
  },

  watch: {
    radio(val) {
      if (val == "orange") {
        this.select_members = " ";
        this.select_members_inactive = " ";
      } else {
        this.select_members = "";
        this.select_members_inactive = " ";
      }
    },

    date() {
      this.dateFormatted = this.formatDate(this.date);
      // console.log(this.dateFormatted);
    },
  },
  async created() {
    this.get_list();
    this.overlay = true;
    this.isLoadingLocations = true;
    this.height = window.innerHeight - 430;
    this.selectedDate = this.get_date(
      this.$store.getters.get_org_details[0].organisation_created_on
    );
    //  this.selectedDate='03/02/2024, 03:35:44 PM';
    (this.minmonth = moment(this.selectedDate, "MM/DD/YYYY").format(
      "YYYY-MM-DD"
    )),
      //  console.log(this.minmonth);
      // console.log(this.$store.getters.get_org_details[0].organisation_created_on);
      // console.log(this.selectedDate, "this.selectedDate");

      await this.month_get();
    await this.GetLocation();
    await this.fetch_details();
  },

  methods: {
    month_get() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonthIndex = currentDate.getMonth();

      let newMonthItems = [];
      const month = this.selectedDate.match(/^(\d{2})\//)[1];

      // if (this.selectedYear === new Date(this.selectedDate).getFullYear()) {
      //   alert(4)
      //   newMonthItems = this.monthNames.slice();
      // }
      if (this.selectedYear < currentYear) {
        newMonthItems = this.monthNames.slice();
      } else if (this.selectedYear === currentYear) {
        newMonthItems = this.monthNames.slice(0, currentMonthIndex + 1);
      } else {
        newMonthItems = this.monthNames.slice();
      }

      this.monthItems = newMonthItems.slice();

      this.formattedMonthItems = this.monthItems.map((month, index) => ({
        text: month,
        value: (index + 1).toString().padStart(2, "0"),
      }));
      this.selectedMonth = (currentMonthIndex + 1).toString().padStart(2, "0");
      if (this.selectedYear == new Date(this.selectedDate).getFullYear()) {
        const startIndex = this.formattedMonthItems.findIndex(
          (item) => item.value === month
        );

        const filteredArray =
          startIndex !== -1 ? this.formattedMonthItems.slice(startIndex) : [];

        this.formattedMonthItems = [];
        this.formattedMonthItems = filteredArray;
        this.selectedMonth = month;
      }

      this.$forceUpdate();
    },
    fetch_details() {
      this.GetLocationitems.forEach((element) => {
        this.locationItems.push({
          location_name: element.loc_name,
          location_id: element.loc_id,
        });
        this.locationscanItems.push({
          location_name: element.loc_name,
          location_id: element.loc_id,
        });
      });
      this.locationscanItems.unshift("ALL");
      this.locationItems.unshift("ALL");
    },

    getweekcal() {
      this.weakmodel = true;
    },

    // download_invoice(item) {
    //   window.open(item);
    //   // console.log(item);
    // },
    async download_invoice(url) {
      var extension = "";
      if (url.report_type === "MONTHLY_PROOF_OF_WORK") {
        extension =
          url.report_s3_key.split(".").pop().split("?")[0] == "pdf"
            ? ".pdf"
            : ".xlsx";
      } else {
        extension = ".xlsx";
      }
      var fileName =
        url.report_type +
        "_" +
        this.get_date(url.report_generated_on) +
        extension;
      axios({
        url: url.report_s3_key,
        method: "GET",
        responseType: "blob", // Set the response type to blob
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });

          // Use the FileSaver library to save the file
          FileSaver.saveAs(blob, fileName);
        })
        .catch((error) => {
          console.error("Error downloading the file:", error);
        });
    },
    async get_list() {
      this.isLoadingexport = true;
      this.overlay = true;
      try {
        await API.graphql(
          graphqlOperation(get_list_of_reports, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
            },
          })
        ).then((res) => {
          // console.log(res,"result");
          this.exporttable = [];
          this.exporttable = JSON.parse(
            res.data.get_list_of_reports
          ).reports.Items;
          // console.log(this.exporttable);
          this.overlay = false;
          this.isLoadingexport = false;
          // console.log(this.exporttable);
        });
      } catch (error) {
        console.log(error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.overlay = false;
        this.isLoadingexport = false;
      }
    },
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    async send_weekly_report() {
      try {
        await API.graphql(
          graphqlOperation(all_weekly_kind_report, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              dynamo_date: this.weakdate,
              user_id:
                this.weeklymembersstatus == "DEACTIVE"
                  ? this.select_members_weekly_inactive
                  : this.select_members_weekly,
              generated_by:
                this.$store.getters.get_org_user_details[0].user_email_id,
              type_report: this.weektypereport,
              user_status: this.weeklymembersstatus,
            },
          })
        );
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: "Weekly Report Generating Soon",
        };
        this.overlay = false;
        this.isLoading = false;
        // console.log(result);
        this.get_list();
      } catch (error) {
        console.log(error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.isLoading = false;
      }
    },
    send_monthly_report() {
      if (
        this.enumreport == "PROOF_OF_WORK" &&
        this.monthlymembersstatus == "DEACTIVE"
      ) {
        if (this.select_members_monthly_inactive_with_proof != "") {
          this.send_monthly_report_monthly();
        } else {
          this.$store.commit("snackbar/showMessage", {
            content: "Please enter all fields",
            color: "red",
          });
        }
      } else if (
        this.monthlymembersstatus == "ACTIVE" &&
        this.enumreport == "PROOF_OF_WORK"
      ) {
        if (this.select_members_monthly_active_with_proof != "") {
          this.send_monthly_report_monthly();
        } else {
          this.$store.commit("snackbar/showMessage", {
            content: "Please enter all fields",
            color: "red",
          });
        }
      } else {
        this.send_monthly_report_monthly();
      }
    },
    async send_monthly_report_monthly() {
      this.isLoading = true;

      // console.log( this.selectedYear.toString()+'-'+this.selectedMonth);
      // console.log(this.yearmonth);

      try {
        await API.graphql(
          graphqlOperation(monthReport, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              user_email_id: this.search_admins,
              offsets: new Date().getTimezoneOffset() * -60000,
              user_status: this.monthlymembersstatus,
              dynamo_month:
                this.selectedYear.toString() + "-" + this.selectedMonth,
              type_report: this.enumreport,
              user_id:
                this.monthlymembersstatus != "DEACTIVE" &&
                this.enumreport != "PROOF_OF_WORK"
                  ? this.select_members_monthly
                  : this.monthlymembersstatus == "DEACTIVE" &&
                    this.enumreport != "PROOF_OF_WORK"
                  ? this.select_members_monthly_inactive
                  : this.monthlymembersstatus == "DEACTIVE" &&
                    this.enumreport == "PROOF_OF_WORK"
                  ? this.select_members_monthly_inactive_with_proof
                  : this.monthlymembersstatus != "DEACTIVE" &&
                    this.enumreport == "PROOF_OF_WORK"
                  ? this.select_members_monthly_active_with_proof
                  : "",
              generated_by:
                this.$store.getters.get_org_user_details[0].user_email_id,
              location_id: this.Locationscan,
              home_location_id: this.LocationData,
              exp_type:
                this.enumreport == "PROOF_OF_WORK"
                  ? Number(this.proofReport)
                  : undefined,
            },
          })
        );
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: "Monthly Report Generating Soon",
        };
        this.overlay = false;
        this.isLoading = false;
        // console.log(result);
        this.get_list();
      } catch (error) {
        console.log(error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.isLoading = false;
      }

      // else {
      //   this.$store.commit("snackbar/showMessage", {
      //     content: "Please enter all fields",
      //     color: "red",
      //   });
      // }
    },
    async send_daily_report() {
      // if (this.search_admins1 !='' ){
      try {
        await API.graphql(
          graphqlOperation(daily_report, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              user_email_id: this.search_admins1,
              offsets: new Date().getTimezoneOffset() * -60000,
              attendence: this.dailystatus,
              dynamo_date: this.computedDateFormatted,
              generated_by:
                this.$store.getters.get_org_user_details[0].user_email_id,
              exp_type:
                this.dailystatus == "PROOF_OF_WORK"
                  ? Number(this.proofReport)
                  : undefined,
            },
          })
        );
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: "Daily Report Generating Soon",
        };
        this.overlay = false;
        this.isLoading = false;
        // console.log(result);
        this.get_list();
      } catch (error) {
        console.log(error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.isLoading = false;
      }
      // }
      // else {
      //   this.$store.commit("snackbar/showMessage", {
      //     content: "Please enter all fields",
      //     color: "red",
      //   });
      // }
    },
    yearly_report() {
      if (this.YearlyMemberstatus == "ACTIVE") {
        if (this.radio != "" && this.select_members != "") {
          this.send_email();
        } else {
          this.$store.commit("snackbar/showMessage", {
            content: "Please enter all fields",
            color: "red",
          });
        }
      } else {
        if (this.radio != "" && this.select_members_inactive != "") {
          this.send_email();
        } else {
          this.$store.commit("snackbar/showMessage", {
            content: "Please enter all fields",
            color: "red",
          });
        }
      }
    },
    async send_email() {
      this.isLoading = true;
      try {
        await API.graphql(
          graphqlOperation(YearlyReport, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              user_id:
                this.YearlyMemberstatus == "ACTIVE"
                  ? this.select_members
                  : this.select_members_inactive,
              year: this.selectedYear,
              user_email_id: this.search_admin,
              user_status: this.YearlyMemberstatus,
              generated_by:
                this.$store.getters.get_org_user_details[0].user_email_id,
            },
          })
        );
        // console.log(this.search_admin)
        //copied from other code.
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: "Yearly Report Generating Soon",
        };
        this.overlay = false;
        this.isLoading = false;
        this.get_list();
        // console.log("Console Result", result);
      } catch (error) {
        console.log(error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.isLoading = false;
      }
    },

    async userlist() {
      try {
        let result = await API.graphql(
          graphqlOperation(ListAllUsers, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
            },
          })
        );

        this.userList = result.data.ListAllUsers.ActiveMembers;
        this.userinactive = result.data.ListAllUsers.InActivemembers;
        var self = this;
        self.userinactive.forEach(function (item) {
          self.selectmembers_inactive.push({
            text: item.user_name,
            value: item.user_id,
          });
        });
        self.userinactive.forEach(function (item) {
          self.selectmembers_Monthly_inactive.push({
            text: item.user_name,
            value: item.user_id,
          });
        });
        self.userList.forEach(function (item) {
          self.selectmembers.push({
            text: item.user_name,
            value: item.user_id,
          });
        });
        self.userList.forEach(function (item) {
          self.selectmembers_Monthly_active_proof_of_work.push({
            text: item.user_name,
            value: item.user_id,
          });
        });
        self.userList.forEach(function (item) {
          self.selectmembers_weekly_active.push({
            text: item.user_name,
            value: item.user_id,
          });
        });
        self.userinactive.forEach(function (item) {
          self.selectmembers_weekly_inactive.push({
            text: item.user_name,
            value: item.user_id,
          });
        });

        self.userList.forEach(function (item) {
          self.selectmembers_Monthly.push({
            text: item.user_name,
            value: item.user_id,
          });
          self.userinactive.forEach(function (item) {
            self.selectmembers_Monthly_inactive_proof_of_work.push({
              text: item.user_name,
              value: item.user_id,
            });
          });
        });
        self.selectmembers_weekly_active.unshift("ALL");
        self.selectmembers_weekly_inactive.unshift("ALL");
        self.selectmembers_Monthly_inactive.unshift("ALL");
        self.selectmembers_Monthly.unshift("ALL");
      } catch (err) {
        console.log("err", err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
      }
    },
    getcal() {
      this.modal = true;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${year}-${month}-${day}`;
    },
  },
};
</script>  























  